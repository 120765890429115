.main-export-container {
  height: 100%;

    tr.MuiTableRow-root {
      height: 48px;
    }
    td.MuiTableCell-root {
      height: 48px;
    }
    th.MuiTableCell-head {
      font-family: Avenir;
      font-size: 12px;
      font-weight: 400;
      color: #B5B5B5;
      border-bottom: none;
      letter-spacing: 0.01em;
    }
    
    .table-export-container {
      padding: 0 39px 0;
      table-layout: fixed;
      td.MuiTableCell-root {
        border-bottom: none;
      }
      thead {
        .MuiTableCell-root {
          padding-top: 32px;
        }
      }
      tbody {
        .MuiTableCell-root {
          font-family: 'Avenir';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.01em;
          color: #3C3C3B;
        }
      }
    }
    
    .MuiTablePagination-actions {
      .MuiButtonBase-root {
        outline: none;
      }
    } 
    
    .MuiTablePagination-selectLabel{
        margin-bottom: 0;
    }
    
    .MuiTableRow-footer {
        justify-content: flex-end;  
    }
    
    .MuiPagination-root {
      .MuiPagination-ul {
        li:first-of-type {
          margin-right: 12px;
        }
        li:last-of-type {
          margin-left: 12px;
        }
      }
    }
    
    
}
