.header {
  button {
     @media (min-height: 400px) {
          font-size: 12px;
          height: 48px;
      }
      @media (min-height: 950px) {
          font-size: 16px;
          height: 56px;
      }
     
  }
}
.reject-main-title, .reject-date-subtitle {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  @media (min-height: 400px) {
    font-size: 14px;
  }
  @media (min-height: 950px) {
    font-size: 16px;
  }
  line-height: 100%;
  color: #414141;
}

.reject-date-subtitle {
  font-weight: 900;
}