th.MuiTableCell-head {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 400;
  color: #B5B5B5; 
}

.table-container {
  table-layout: fixed;
  td.MuiTableCell-root {
    border-bottom: none;
  }

}

.MuiTablePagination-actions {
  .MuiButtonBase-root {
    outline: none;
  }
} 

.MuiTablePagination-selectLabel{
    margin-bottom: 0;
}

.MuiTableRow-footer {
    justify-content: flex-end;  
}

.main-container {
  height: 100%;
  padding: 0 40px 0;
    tr.MuiTableRow-root {
      height: 48px;
    }
    td.MuiTableCell-root {
      height: 48px;
    }

}

.footer {
  padding-bottom: 10px;
  bottom: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

  button.MuiPaginationItem-root {
    outline: none;
  }
  td.MuiTableCell-root {
    height: 70px;
    overflow: auto;
    border-bottom: none;
  }
}

.MuiPagination-root {
  .MuiPagination-ul {
    li:first-of-type {
      margin-right: 12px;
    }
    li:last-of-type {
      margin-left: 12px;
    }
  }
}

.MuiDataGrid-filterForm {
  select:focus {
    background-image: none !important;
  }

  select {
    min-height: revert;
  }
}