.inactive-menu-icon {
  width: 10px;
  visibility: hidden;
  font-size: 20px;
  margin-right: -10px;
  display: inline-block;
}

.active-menu-icon {
  max-width: 100px;
  width: 10px;
  font-size: 20px;
  margin-right: -10px;
  display: inline-block;
  visibility: visible;
}

.table-menu {
      
  @media (min-height: 400px) {
    span {
      font-size: 0.8rem;
      line-height: 1.2;
    }

  }
      
  @media (min-height: 950px) {
    span {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
  

  
  letter-spacing: 0px;
  font-weight: 400;
  box-sizing: inherit;
  list-style: none;
  margin: 0px;
  color: inherit;
  outline: 0px;
}