:root {
    // colors
    --color-carbone: #3C3C3B;
    --color-carbone-300: #797979;
    --color-carbone-500: #545454;
  
    --color-white: #FFFFFF;
    --color-near-white: #F5F6F6;
    --color-lighter-grey: #EEEEEE;
    --color-light-grey: #C6C6C6;
    --color-medium-grey: #838383;
    --color-near-black: #272727;
    --color-grey-900: #3C3C3B;
    --color-grey-800: #50504F;
    --color-grey-500: #919191;
    --color-grey-300: #C8C8C8;
    --color-grey-200: #E3E3E3;
  
    --color-blue-500: #41A8F9;
    --color-blue-600: #1294F8;
    --color-blue-700: #0673C6;
    --color-blue-900: #022E4F;
    --color-light-blue: #F1F8FF;
  
    --color-yellow-500: #FECC00;
  
    --color-red-400: #FD7B62;
    --color-red-500: #FC5130;
  
    --color-green-500: #04E762;
    --color-green-900: #09C959;
  
    --color-warning-900: #EE9207;
  
    --color-reject-oca: #feaca8;
    --color-reject-done: #70ad47;
    --color-reject-export: #b9edff;
    --color-reject-pending: #ffe497;
    --color-reject-failed: #ff3809;
    --color-reject-treat: #cccccc;
    // sizes
    --topbar-height: 72px;
    --tabsbar-height: 48px;
  }