#info-panel {
  height: calc(100vh - 270px);

  .container {
    background-color: var(--color-light-blue);
    width: 50%;
    min-width: 500px;
    height: 50%;
    min-height: 200px;
    border-radius: 12px;

    .title {
      font-weight: 800;
      font-size: 36px;
      margin-top: 20px;
    }

    .subtitle {
      font-weight: 400;
      font-size: 16px;
      margin-top: 1rem;
      @media (min-height: 800px) {
        margin-top: 2rem;
      }
    }
  }
}