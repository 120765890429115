
.sheet-button-wrapper {
  display: flex;
  width: 90%;
  margin-top: 20px;
  @media (min-height: 800px) {
    margin-top: 60px;
  } 
  height: calc(100vh - 350px);
  &.with-filter {
    height: calc(100vh - 350px);
  }
  @media (min-height: 800px) {
    height: calc(100vh - 390px);
    &.with-filter {
      height: calc(100vh - 490px);
    }
  }
  overflow:auto;

  .MuiSvgIcon-root {
    color: #183B68;
  }

  .MuiList-root {
    width: 100%;
  }

  .sheet-accordion-summary {
    height: 72px;
    width: 100%;
  }
  
  .sheet-button {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    justify-content: center;
    background: #E8F5FE;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    border: none;
    outline: none;
  }

  .sheet-button-inactive {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    justify-content: center;
    background: #FBFBFB;
    opacity: 0.25;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    border: none;
  }

  .title {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 120%;
    color: #183B68;

  }

  .subtitle {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #B5B5B5;
  }  

  .MuiListItem-root {
    padding: 0;
    width: 90%;
  }

  .sheet-accordion-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    .sheet-related-protection {
      width: 100%;
      height: 40px;
      overflow: hidden;
      word-wrap: break-word;
      white-space: nowrap;
      text-overflow: ellipsis;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 10px;
      margin-bottom: 8px;
      background: #FFFFFF;
      border-radius: 12px;

      &:hover {
        cursor: pointer;
      }
    }

    .dp-active {
      opacity: 1
    }
    .dp-inactive {
      opacity: 0.40
    }
  }

  .no-dp {
    font-family: 'Avenir';
    font-style: normal;
    line-height: 120%;

  }
}
