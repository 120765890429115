
.reject-main-container {
  padding: 0;
  height: 100vh;
  margin-top: 20px;
  border-radius: 0;
  
  .MuiTableRow-root {
    background-color: #F3F7FB;
  }
  .reject-table-container {
    background-color: var(--color-white);
    border-top: 1px solid #e0e0e0;
    
    @media (min-height: 400px) {
      height: calc(100vh - 280px)
    }
    
    
    @media (min-height: 950px) {
      height: calc(100vh - 300px)
    }
    

    .MuiTableCell-head {
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 400;
      @media (min-height: 400px) {
        height: 32px;
        font-size: 12px;
      }
      @media (min-height: 950px) {
        height: 40px;
        font-size: 16px;
      }

      color: #7B7B7B;
      border-bottom-width: 0;
      text-transform: uppercase;      
    }

    .reject-table-content {
      width: 4000px;
      overflow: scroll;

      @media (min-height: 400px) {
        input, .select, .adornment {
          font-size: 12px;
        }   
        svg {
          font-size: 1.3rem
        }
      }
      @media (min-height: 950px) {
        input, .select, .adornment {
          font-size: 16px;
        }
        svg {
          font-size: 1.5rem
        }
      }
     
      .reject-row {
        td {
          @media (min-height: 400px) {
            font-size: 12px;
          }
          @media (min-height: 950px) {
            font-size: 14px;
          }
          
        }
      
      }
      tr.MuiTableRow-root.MuiTableRow-head {
        overflow: hidden;
        @media (min-height: 400px) {
          font-size: 14px;
          height: 30px;
        }
        @media (min-height: 800px) {
          font-size: 16px;
          height: 40px;
        }
        background-color: var(--color-white);
      }

      td.MuiTableCell-root {
          @media (min-height: 400px) {
            padding-bottom: 8px;
            padding-top: 8px;
            height: 48px !important;
            border-bottom: 6px solid var(--color-white);
          }
          @media (min-height: 950px) {
            padding-bottom: 16px;
            padding-top: 16px;
            height: 60px;
            border-bottom: 8px solid var(--color-white);
          }
      }

      .pinned-cell {
        background-color: #F3F7FB;
        border-right: 4px solid #FFFFFF;
        z-index: 1000;
      }

      .active-row {
        background-color: #1976d21f;
      }
    }
  }
  .analysis-select {
    .select {
      border: 3px solid var(--color-blue-500) !important;
    }
  }
}