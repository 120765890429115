.dates-container {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dates-span {
flex: 0.3;
}