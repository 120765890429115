.export-preview {
  padding: 27px 39px 28px;
  .date-label {
    font-family: 'Avenir';
    width: 68px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #183B68;
  }

  .button-csv {
    outline: none;
    text-transform: none;
    width: 260px;
    padding: 6px 6px 6px 8px;
    background: #F3F7FB;
    border-radius: 12px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 130%;
    color: #616465;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    &:hover {
     background-color: revert;
    }
  }

  .formgroup {
    width: 300px;

    .form-control-checkbox {
      height: 24px;
    }
  }
}  

.preview-main-container {
  background-color: #F3F7FB !important;
  padding: 20px 120px 0 120px;

  @media (min-height: 800px) {
    padding-top: 60px;
  }

  height: 100vh;
}
.preview-table-container {
  width: 100%;
  @media (min-height: 150px) {
    height: 45vh !important;
  }
  @media (min-height: 1100px) {
    height: 60vh !important;
  }

  .preview-table-content {
  
    tr.MuiTableRow-root {
      height: 60px;
    }
    td.MuiTableCell-root {
      border-bottom: 8px solid #F3F7FB;
      height: 60px;
    }
    .MuiTableRow-hover:hover {
      background-color: var(--color-white);
    }
    .MuiTableRow-root {
      background-color: var(--color-white);
   
   
      .select {
        height: 36px;
        background: var(--color-white);
        border: 1px solid rgba(159, 159, 159, 0.2);
        border-radius: 4px;
      }
      form {
        height: 36px;
        background: var(--color-white);
        border: 1px solid rgba(159, 159, 159, 0.6);
        border-radius: 4px;
  
      }
    }
  }
  
  .MuiTableHead-root th.MuiTableCell-head {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #7B7B7B;
    background-color: #f3f7fb;
    border-bottom-width: 0;
    text-transform: uppercase;      
  }
}

