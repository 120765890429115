.history-action-container {
  margin-top: 29px;
  height: calc(100vh - 360px);
  overflow: auto;
  position: relative;
  
  @media (min-height: 800px) {
    margin-top: 52px;
    height: calc(100vh - 380px);
  }

  .history-action {
    margin-bottom: 20px;
  }
}