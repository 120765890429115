button.draggable-button {
  position:absolute !important;
  bottom: 10%;
  right: 2%;
  color: var(--color-blue-500);
}

button.draggable-button:hover {
  cursor: grab !important;
}

button.draggable-button svg {
  font-size: 12px !important;
}