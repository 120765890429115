.select {
  height: 36px;
  border-radius: 4px;
  font-family: Avenir;
  background-color: var(--color-white);
 
  .MuiSelect-select {
    padding: 16px;
  }

  .MuiInput-input:focus {
    background-color: revert;
  }
}