th.MuiTableCell-head {
  padding-left: 12px;

  @media (min-height: 400px) {
    svg {
      font-size: 16px;
    }
   
  }
  @media (min-height: 950px) {
    svg {
      
      font-size: revert;
    }
   
  }
}