.reject-main-container {

  #filter-select {
    padding-top: 20px;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1;
    width: 450px;
    max-height: 300px;
    overflow-y: auto;
    flex-direction: column;
    min-height: 80px;
    background-color: #E8F5FE;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    .input-title {
      font-family: Avenir;
      font-size: 12px;
    }

    .param {
      width: 25%;
    }
  }
}

.sheet-list {
  #filter-select {
    padding-top: 20px;
    position: absolute;
    top: -70px;
    left: 50px;
    z-index: 1;
    width: 450px;
    max-height: 300px;
    overflow-y: auto;
    flex-direction: column;
    min-height: 80px;
    background-color: #E8F5FE;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  
    .input-title {
      font-family: Avenir;
      font-size: 12px;
    }
  
    .param {
      width: 25%;
    }
  }
}

#filter-select {
  .close-button {
    position: absolute;
    top: 5px;
    left: 0;
  }
}

#menu-.MuiModal-root {
  z-index: 3000;
}