.topbar {
  .MuiFormControl-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--color-white);
      border-width: 0;
    }
  
    .MuiSelect-icon {
      color: var(--color-white);
    }
  
    .MuiOutlinedInput-input {
      color: var(--color-white);
    }
  
    fieldset {
      border: 0;
      outline: none;
  }
  
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: "0px solid #484850";
    border-radius: "0 0 0 0";
  
  }
  .MuiOutlinedInput {
    border: 0;
  }
  
  .MuiInputLabel-shrink {
    display: none;
  }
  
  .MuiInputLabel-root {
    color: var(--color-white);
  }
  }
  
}
