.date-picker {
  width: 240.5px;
  height: 56px;
  margin-left: 16px;
  margin-right: 16px;
  background: #FFFFFF;
  border: 1px solid #D0D9E1;
  border-radius: 4px;
  
  .MuiSvgIcon-root {
    color: #183B68
  }

  button.MuiButtonBase-root {
    outline: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
