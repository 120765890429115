.summary-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #F3F7FB;

  .summary-table-container {
    border: none !important;
    flex: 0.5;
    padding: 60px 120px 0 120px;
    height: 60vh;
    .MuiDataGrid-columnHeaderTitle {
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #7B7B7B;
      border-bottom-width: 0;
      text-transform: uppercase;
      
    }

    .MuiDataGrid-columnHeaders {
      height: 72px;

      border-bottom: 0;
      div.MuiDataGrid-columnSeparator {
      display: none;
      }
    }

    .MuiDataGrid-cell{
      border-bottom: 8px solid #F3F7FB !important;
    }

    .MuiDataGrid-cell:focus-within{
      outline: none !important;
    }
    
    .MuiDataGrid-row {
      background-color: var(--color-white) !important;
    }
  }
}