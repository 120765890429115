.header {
  padding: 40px 0 0 16px;
}

label {
  height: 100%;
}

.main-title {
  font-family: 'Avenir';
  font-weight: 800;
  font-style: normal;
  color: var(--color-near-black);
  font-size: 32px;
  line-height: 100%;
}

.sub-title {
  font-family: 'Avenir';
  color: #B5B5B5;
  width: 164px;
  height: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
}
