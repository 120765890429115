#home {
  >.MuiTabs-root {
    background-color: var(--color-carbone);
    height: var(--tabsbar-height);


    .MuiTabs-indicator {
      height: 0;
    }

    .Mui-selected {
      opacity: 1 !important;
      outline: none;
    }

    .tabs-menu-item {
      color: var(--color-white);
      font-size: 12px;
      letter-spacing: 0.01em;
      font-family: Avenir;
      font-style: normal;
      font-weight: bold;
      text-transform: none;
      opacity: 0.3;
    }

  }

  .MuiTabPanel-root {
    padding: 0;
    height: 100%;
  }
}