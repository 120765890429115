.data-view-container {
  margin-top: 36px;
  border-top: 1px solid var(--color-lighter-grey);
  overflow: hidden;
}


.sheet-info {
  height: 100%;
  padding-top: 24px;
  padding-left: 24px !important;
  border-left: 1px solid var(--color-lighter-grey);
  border-right: 1px solid var(--color-lighter-grey);
  .sheet-nav-button {
    width: 36px;
    height: 36px;
    background-color: rgba(24, 59, 104, 0.07);
    border-radius: 12px;
    color: #183B68;

  }

  .info-title {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .container-title {
    line-height: 1.5em;
  }
  .serial-title {
    height: 16px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #183B68;
    
  }

  .protection-title {
    min-height: 1em;
    max-width: 550px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    color: #183B68;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 3.5em;
    overflow: hidden;
  }

  #lineNb {
    margin: 5px auto;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #183B68;
  }
}

.sheet-filter {
  background-color: var(--color-blue-500);
  border-radius: 8px;
  width: 100%;
  margin-top: 1rem;
  padding: .5rem 0 .5rem 1rem;

  .filter-text {
    color: white;
    font-family: 'Avenir';
    flex: 1;
  }

  .close-button {
    color: white;
  }
}

.sheet-list {
  padding-left: 24px;

  .sheet-list-button {
    padding-top: 24px;

    .filter-icon {
      color: var(--color-carbone);
    }

    button:focus {
      outline: none;
    }
  }
  .sheet {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 128%;
    display: flex;
    align-items: center;
    color: var(--color-carbone);  
  }
  
  #line-number {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: var(--color-carbone);
  }

}

#sheet-fieldset {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #183B68;
  opacity: 0.2;
}

.sheet-details-label {
  width: 40%;
  height: 20px;
  
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 120%;
  color: #183B68;
  
  opacity: 0.6;
  
}

#sheet-analysis {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-right: 12px !important;
  gap: 40px;
  background: rgba(65, 168, 249, 0.12);
  border: 1px solid var(--color-blue-500);
  border-radius: 16px;

  .label-analysis {

    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    color: #183B68;
  }

  .wrapper {
    width: 100%
  }

  .wrapper-location {
    width: 20%
  }

  .locations {
    margin-bottom: 26px;
  }
  #location-table {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 120%;
    color: var(--color-carbone);
  }

  .sheet-lines {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .add-location {
    color: var(--color-carbone);
  }

  #reaccord {
    height: 16px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: var(--color-near-black);
  }

  .MuiInput-root {
    border: none !important
  }
}

#sheet-save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px 6px 8px;

  width: 398px;
  height: 56px;

  background: var(--color-blue-500);

  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08), -2px -2px 7px rgba(0, 0, 0, 0.06);
  border-radius: 30px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.sheet-description {
  height: calc(100vh - 410px);
  overflow: auto;
  overflow-x: hidden;
  margin-top: 20px;
  @media (min-height: 800px) {
    margin-top: 43px;
    height: calc(100vh - 425px);
    padding-bottom: 10px;
  }

  .sheet-form-info {
    display: flex;
    flex-wrap: wrap; 
    flex-direction: row;
    margin: 30px 0;
  } 

  .sheet-form-unit {
    display: flex;
    width: 50%;
    margin-bottom: 16px;
  }

  .textfield {
    padding-left: 16px;
  }
}


.history {
  padding-top: 24px;

  .history-label {
    display: block;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 128%;
    color: #183B68;
    width: 230px;
  }

  .history-sub-label {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #183B68;
  }
}


legend {
  overflow: hidden;
}

legend:after {
  background-color: #183B68;
  content: "";
  opacity: 0.5;
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 75%;
}

legend:before {
  right: 0.5em;
  margin-left: -50%;
}

legend:after {
  left: 21px;
  margin-right: -50%;
}

.error-message {
  color: red;
  font-size: 12px;
}

.filter-container {
  min-height: 0;
  max-height: 100px;
  overflow: auto;
}