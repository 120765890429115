.action-container {
  align-items: center;
  flex-wrap: wrap;

  .text-container {
    width: 100%;
    flex: 1;
  }

  .text {
    font-family: 'Avenir';
    font-style: normal;
    font-size: 14px;
    color: #183B68;
  }

  .icon {
    font-size: 28px;
    padding: 6px;
    border-radius: 25px;
  }

  .title {
    width: 200px;
    font-weight: 800;
  }

  .date {
    font-weight: 400;
    font-size: 12px;
    color: var(--color-grey-300);
  }

  .small-bold {
    font-weight: 800;
    font-size: 12px;
  }

  .small-light {
    font-weight: 400;
    font-size: 12px;
  }

  .blue {
    color: var(--color-blue-500);
  }

  .blue-background {
    background-color: var(--color-blue-500);
  }

  .yellow-background {
    background-color: #FDF2D0;
  }

  .green-background {
    background-color: var(--color-green-900);
  }

  .red-background {
    background-color: var(--color-red-500);
  }

  .white {
    color: white;
  }

  .outlined {
    border: 1px solid #183B68;
    color: #183B68;
  }
}