.export-header {
  border-bottom: 1px solid var(--color-lighter-grey);
  padding: 27px 60px 28px;

  .export-header-titles {
    flex: 2;
  }
  .export-header-buttons {
    flex: 3;
  }
}
.export-main-title {
  display: block;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 120%;
  color: #414141;
}

.summary-main-title {
  display: block;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 120%;
  color: #414141;
  width: 400px;
}
.export-subtitle {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #414141;
}
